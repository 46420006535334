<template>
  <div class="library">
    <router-link
      v-for="(section, key) in sections"
      :key="`${key}-section`"
      :to="{ path: section.path }"
      v-slot="{ navigate }"
      custom
    >
      <div class="library__link" @click="navigate">
        <img class="library__link_image" :src="section.image" alt="Site sections link" />
        <h2 class="library__link_title">{{ section.title }}</h2>
        <span class="library__link_description">{{ section.description }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {},

  data() {
    return {
      sections: [
        {
          image: require("@/assets/images/library/library-blog.png"),
          title: "Blog / arttextstyle",
          description:
            "An eclectic collection of art and information that entertains, intrigues, and inspires us.",
          path: "/media/arttextstyle",
        },
        {
          image: require("@/assets/images/library/library-news.jpg"),
          title: "In The News",
          description:
            "Some of what others have written about browngrotta arts and our artists in newspapers, magazines and online.",
          path: "/media/in-the-news",
        },
        {
          image: require("@/assets/images/library/library-releases.png"),
          title: "Press Releases",
          description: "Details about exhibitions, publications and other browngrotta activities.",
          path: "/media/press-releases",
        },
        {
          image: require("@/assets/images/library/library-memoriam.jpg"),
          title: "Lives Well Lived",
          description:
            "Commemorating artists and friends of browngrotta arts who are no longer with us.",
          path: "/media/lives-well-lived",
        },
        {
          image: require("@/assets/images/library/library-loose.png"),
          title: "Loose Threads",
          description: "Interviews, videos, archives and other resources and items of interest.",
          path: "/media/loose-threads",
        },
        {
          image: require("@/assets/images/artworks/acquisitions__image.jpg"),
          title: "Acquisitions",
          description:
            "News about museum acquisitions of work by artists that work with browngrotta arts.",
          path: "/media/acquisitions",
        },
      ],
    };
  },
  head: {
    title: function () {
      return {
        inner: "Media browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Media browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Media browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Media browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Media browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Media browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Media browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Media browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Media browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.library {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2.8rem;
  grid-row-gap: 6.5rem;
  padding: 20rem 10.5rem 18.5rem;
  @media screen and (max-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $xs) {
    grid-template-columns: 1fr;
    grid-row-gap: 7.5rem;
    padding: 20rem 2rem 18.5rem;
  }

  &__link {
    cursor: pointer;

    &_image {
      height: 80%;
      width: 100%;
      border-radius: 4px;
    }

    &_title {
      font-size: 4rem;
      line-height: 5rem;
      margin: 1.3rem 0rem 2rem;
      @media screen and (max-width: $xs) {
        font-size: 4.8rem;
        line-height: 5.3rem;
        margin: 0.6rem 0rem 1rem;
      }
    }

    &_description {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
}
</style>
